.centeredText {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.virtualtour_embed {
  display: none;
}
