@import 'theme/colors';
@import 'theme/variables';
@import 'grid/grid';
@import 'layout/layout';
@import 'theme/breakpoints';
@import 'theme/spacing';

.mobileNav-home {
  color: $colorBlack;
  padding: $spc-m;
  border-bottom: 1px solid $color-gray-200;
  font-family: Ubuntu;

  svg {
    // width: 1.25rem;
    // height: 1.25rem;
    // fill: $color-student-accent-fuschia;
    margin-bottom: -5px;
    margin-left: 0.5rem;
  }

  &:hover {
    background: $colorLightTeal;
  }

  a {
    margin-left: 1.5rem;
    color: $color-gray-800;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  a:hover,
  a:active {
    text-decoration: none;
    text-shadow: 0.5px 0 0 currentColor;
  }
}

.mobileNav {
  @extend .fc-mobile-nav;
  position: fixed;
  display: none;
  top: 0;
  left: auto;
  right: -100%;
  background-color: $colorWhite;
}

.mobileNavActive {
  @extend .fc-mobile-nav--active;
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  padding-bottom: 5rem;
  overflow-y: auto;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menuHeader {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  color: $color-gray-800;
}

.cardTitle h1 {
  font-size: 24px !important;
}

.hamburgerBtn {
  color: $color-gray-600;
  svg {
    width: 24px;
    height: 24px;
    min-width: initial;
    min-height: initial;
  }
}

.expanded {
  h2 {
    font-weight: bold !important;
  }
  li {
    margin: 0;
    &:last-child {
      border-bottom: none;
    }
  }
}

.accordionHeader {
  :global(.no-icon) {
    width: 0;
  }
}

.accordionSection {
  padding-right: 24px;
  padding-left: 24px;
  ul {
    padding: 0;
  }
  > div {
    flex-direction: row !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  border-bottom: 1px solid $color-ui-border;
}

.accordionClose {
  color: $color-student-primary-teal;
  margin: $spc-m;

  svg {
    color: inherit;
  }
}

.justLink {
  :global(.title) {
    color: $color-student-primary-teal;
    font-size: $h3-font-size;
    padding-left: 0;
  }
}

:global {
  .bigmenu-open {
    overflow-y: hidden;
  }
}

.menuTitle {
  text-align: left;
  display: inline-block;
  margin: $spc-s $spc-m $spc-xs $spc-m;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: $color-gray-800;
  margin-left: 0px;
}

.menuFooter {
  display: none;
  color: $color-student-neutral-dark-grey;
  padding: $spc-xs $spc-m;

  p {
    margin-bottom: 0;
  }
}

.bottomMenu {
  border-top: 1px dashed $color-ui-border;
  padding-top: $spc-xs;
}

.logoutBtn {
  color: $colorWhite;
  text-align: center;
  width: 93.5%;
  background: $button-secondary;
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-top: 12px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-left: 12px;
  // background: linear-gradient(180deg, $color-student-primary-teal 0%, #005a61 100%);
  // box-shadow: 0 1px 1px 0 $color-ui-shadow-random-black4;
  // border: 1px solid $color-random-cyan4;
  text-transform: uppercase;
  font-family: Ubuntu;

  button {
    width: 100%;
    justify-content: center;
  }
}

.avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: $color-cyan-400;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 600 !important;
  line-height: 26.67px;
  margin-right: $spc-s;
  color: $colorWhite;
  font-weight: normal !important;
  margin-bottom: -16px;
}
.powerBuddyHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.powerbuddyTextCursor {
  cursor: pointer;
}
