@import 'colors/colors';
@import 'themeNeon/colors';

// These styles only apply to the custom college card component, but
// are needed in order to overcome the feature-flagged styles
// which hide the built-in college card component in PowerBuddy.
.collegeCard {
  display: flex !important;
}

.collegeCardTitle {
  font-weight: bold !important;
}

.collegeCardImage {
  background-color: $color-gray-100;
}

.collegeCardButtons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.collegeCardButtonIcon {
  display: inline-block;
}

.collegeConnectionButtons {
  display: flex;
  gap: 0.625rem;
}

.collegeConnectionButtons button {
  border: 1px solid $color-gray-300;
  height: 1.875rem;
  outline: none !important;
}
