@import 'theme/colors';
@import 'themeNeon/colors';
@import 'theme/spacing';

.videoHeader {
  display: flex;
  justify-content: space-between;
  font-weight: 600 !important;
  margin-bottom: -15px !important;
}

.videoCard {
  padding-bottom: $spc-m !important;
}

.videoCard video {
  height: 100% !important;
}

.videoSubHeader {
  color: $color-gray-600 !important;
  font-style: italic !important;
  font-size: 12px !important;
  margin-bottom: -10px !important;
  font-weight: 400 !important;
}
