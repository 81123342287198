@import 'colors/colors';
@import 'themeNeon/colors';
@import 'theme/spacing';
@import 'theme/variables';

.gradient {
  background-image: linear-gradient(to left, #faf6fe, $color-purple-500);
  height: 1.5rem;
  width: '100%';
}

.scale {
  width: 100%;
}

.scaleLabel {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.map {
  height: 100%;
  width: 100%;
  &:hover {
    fill: $color-purple-800 !important;
    opacity: 0.8 !important;
  }
}

.toolTip {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.35);
  background-color: $color-purple-800;
}
