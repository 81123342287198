.dropdown {
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  z-index: 1;
  transition: display 0.5s; /* add a transition to delay hiding */
  transform: translate(-40%, 5%);
  text-align: center;
  border-radius: 5px;
}

.ulStyle {
  margin: 0;
  padding: 0;
}

.liStyle {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0px;
  }
  width: 145px;
  list-style: none;
  &:hover,
  &:focus {
    text-decoration: underline #100f0f;
  }
}

.powerbuddyLaunchButton {
  display: flex;
  align-items: center;
  border: 0px;
  background: transparent;
  cursor: pointer;
  position: relative;

  svg {
    fill: none;
  }
}
