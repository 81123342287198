#boxSizing * {
  box-sizing: content-box; // default box-sizing is border-box, but need to override specifically for the input textarea of the PowerBuddy chatbot to ensure correct height. Applying to all of PowerBuddy to cover edge-cases
}

.disableAddToListButton {
  // the CSS id for the "Add to List" button lives in the PowerBuddy repo
  // see here: https://gitlab.com/powerschoolgroup/unified-classroom/powerbuddy/powerbuddy/-/blob/main/src/components/BotMessage/ContextData/CollegeCard.tsx?ref_type=heads#L176
  // we need to use :global pseudoselector to prevent CSS module prefixes being appended
  & :global(#favorite-college-btn) {
    display: none;
  }
}

// If the custom college card component is being used,
// then hide the college card component built into PowerBuddy
// This allows us to switch between the card the user sees
// via feature flag without modifying PowerBuddy until
// we are ready to remove the built-in college card
.disableBuiltInCollegeCard [data-testid='college-card'] {
  display: none;
}
