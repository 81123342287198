@import 'colors/colors';
@import 'themeNeon/colors';

.pbCareerCard {
  margin: 16px 0px 16px 0px;
}

.careerFavoriteCardTitle {
  font-weight: bold !important;
  margin-bottom: 10px;
}

.careerFavoriteCardImage {
  object-fit: cover !important;
}

.careerFavoriteCardButtons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.careerFavoriteCardButtonIcon {
  display: inline-block;
}