@import 'theme/colors';
@import 'theme/variables';
@import 'grid/grid';
@import 'layout/layout';
@import 'theme/breakpoints';
@import 'theme/spacing';

$linkHighlightLineWeight: 0.25rem;

.header {
  background-color: $color-white;
  border-bottom: 1px solid $global-divider;
}

.headerTopSection {
  background-color: $color-white;
  color: $color-gray-800;
  padding: 0 $spc-l;
  height: 3rem;
  font-size: 0.8625rem;
  line-height: 2rem;
  display: flex;
  justify-content: space-between;
}

.headerTopSectionLink {
  color: inherit;
  margin-left: $spc-s;

  &:hover {
    text-decoration: underline;
  }
}

.nav {
  position: relative;
  display: flex;
  color: $color-gray-800;
  height: 3rem;
  font-size: 1.728rem;
  background-color: $color-white;
}

.navRight {
  position: relative;
  display: inline-block;
  align-items: right;
  color: $color-gray-800;
  height: 3rem;
  background-color: $color-white;
  line-height: 3rem;
}

.navContainer {
  @extend .fc-main-nav__container;
  justify-content: left;
  vertical-align: middle;
  margin: 0;
  display: flex;
}

.logoWrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 1.5rem;
  font-weight: 100;
  color: $color-gray-800;
}

.logo {
  max-width: 10.75rem;
  margin-right: $spc-xs;
}

.emblem {
  max-width: 18.75rem;
  margin-right: $spc-s;
}

.logoDivider {
  margin: 0 $spc-s;
  font-style: normal;
  color: $colorWhite;
  line-height: 1.667rem;
}

.navList {
  @extend .fc-main-nav__list;
  width: auto;
  margin-bottom: 0;
  text-align: left;
  align-items: stretch;
}

.navItem {
  list-style-type: none;
  white-space: nowrap;
  margin: 0;
}

.navLink {
  text-decoration: none;
  font-size: 1rem;
  color: $color-gray-800;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  &:hover {
    text-decoration: none;
    color: $color-gray-800;
    .navLinkBorder {
      border-bottom: 4px solid white;
    }
  }
}

.navLinkBorder {
  padding: 0.2rem $spc-s;
  margin: 0 $spc-xs/2;
  font-size: 1rem;

  border-bottom: $linkHighlightLineWeight solid transparent;
  border-top: $linkHighlightLineWeight solid transparent;
}

.activeLink {
  .navLinkBorder {
    padding: 0.2rem 1rem;
    border-bottom: $linkHighlightLineWeight solid white;
  }
}

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: $spc-xs;
}

.schoolName {
  text-transform: uppercase;
}

.messages {
  padding-right: $spc-m;
  color: inherit;

  a {
    color: $color-navy-500;
  }
}

.mailIcon {
  svg {
    width: 18px;
    height: 18px;
  }
}

.selectUserCombo {
  color: inherit;
  display: flex;
  min-width: 150px;
}
.labelCls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  // this is a very specific override to the ComboBox label
  span {
    font-weight: normal;
    padding: 0;
    margin: 0;
  }
}
.selectCls {
  display: flex;
  align-items: center;
  padding: 0 0 0 $spc-s;
  border-bottom: none;
  min-width: 0;
  flex: none !important;

  select {
    padding: 0 2rem 0 0;
    min-width: 0;
  }
  svg {
    width: 14px;
    height: 14px;
  }
}
.languagesComboBox {
  display: inline-block;
  margin: 0 1em 0 0.5em;
}
.languagesDropdown {
  min-width: 0;
  line-height: $spc-l;
  select {
    padding: 0 2rem 0 0.5rem;
    min-width: 0;
  }
  figure {
    svg {
      height: 15px;
      width: 15px;
    }
  }
}
.languagesDropdownContainer {
  display: inline-block;
  width: 300px;
  text-align: right;
}
.bigMenu {
  position: absolute;
  font-size: 1rem;
  z-index: 1000;
  min-width: 18rem;
}

.navMenu {
  font-size: 1rem;
  z-index: 100;
  min-width: 25 rem;
}

@media (max-width: $smallDesktop) {
  .headerTopSection {
    display: none;
  }

  .mailIcon {
    margin-top: 8px;
    svg {
      width: 26px;
      height: 26px;
    }
  }

  .badge {
    position: absolute;
    top: 1px;
    left: -10px;
  }

  .messages {
    padding-right: $spc-xs;
    position: relative;
  }

  .selectUserCombo {
    min-width: 0;
  }

  .labelCls {
    flex-direction: column;
    span {
      display: none;
    }
    select {
      padding-right: 2rem;
      min-width: 0;
    }
  }

  .selectCls {
    flex: 1 !important;
  }
}

.careers2Menu {
  position: absolute;
  font-size: 1rem;
  z-index: 1000;
  min-width: 18rem;
  svg {
    color: $colorBlack;
  }
}

.careers2Bg {
  background-color: $color-white;
}

.careers2TopBg {
  background-color: $color-white;
}

.c2Nav {
  display: flex;
  color: $color-gray-800;
  height: 48px;
  padding-left: 14px;
  background-color: $color-white;
}

.c2NavContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .mailIcon {
    margin-top: 8px;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .badge {
    position: absolute;
    top: 0px;
    left: -2px;
  }

  .messages {
    padding-right: $spc-xs;
    position: relative;
    padding-left: 10px;
    &:hover {
      content: '';
      padding-left: 10px;
      padding-top: 6px;
      padding-bottom: 6px;
      background: $color-gray-100;
      .badge {
        top: 6px !important;
        left: -2px !important;
      }
    }
    &:active {
      content: '';
      padding-top: 8px;
      border-bottom: 4px solid $color-gray-300;
    }
  }

  .selectUserCombo {
    min-width: 0;
  }

  .labelCls {
    flex-direction: column;
    span {
      display: none;
    }
    select {
      padding-right: 2rem;
      min-width: 0;
    }
  }

  .selectCls {
    flex: 1 !important;
  }

  .c2FlexContainer {
    display: flex;
    align-items: center;
  }

  .withSeparator {
    & > {
      ::before {
        content: ' ';
        position: relative;
        height: 37px;
        width: 0px;
        border-left: 2px solid $color-random-blue10;
        opacity: 0.6;
        display: none;
      }
    }
  }

  .c2LogoutBtn {
    font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $colorWhite;
    padding: 9px 16px 9px 16px;
  }

  .c2LogoWrapper {
    display: inline-flex;
    padding: $spc-xs;
    color: $color-gray-800;
    font-family: $font-karla-fallback-stack;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-decoration: none;
    @media (max-width: $tablet - 0.1rem) {
      text-indent: -9999px;
    }
  }

  .c2Logo {
    margin: 0;
    svg {
      width: 127px;
      height: 30px;
      margin: 0;
      path {
        fill: $colorWhite;
      }
    }
    @media (max-width: $tablet - 0.1rem) {
      display: none;
    }
  }

  .c2Emblem {
    @media (min-width: $tablet) {
      display: none;
    }
  }

  .hamburger {
    margin-right: 0.8rem;
  }

  .navLinkBorder {
    font-family: Ubuntu, sans-serif;
  }

  .activeLink {
    .navLinkBorder {
      border-bottom: $linkHighlightLineWeight solid transparent;
    }
  }

  .navLink {
    color: $color-gray-500 !important;
    cursor: pointer;

    &.after {
      border-radius: 3px;
      bottom: 0;
      content: '';
      display: block;
      height: 3px;
      inset-inline-end: 16px;
      inset-inline-start: 16px;
      position: absolute;
    }
    &[aria-expanded='true'] {
      background-color: $color-white;
    }
    &:focus {
      outline: none;
    }
  }

  .noMobile {
    @media (max-width: $smallDesktop - 0.1rem) {
      display: none;
    }
  }

  .noDesktop {
    @media (min-width: $smallDesktop) {
      display: none;
    }
  }

  .selectUserCombo {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
  }
}

a.skipMain {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
a.skipMain:focus,
a.skipMain:active {
  color: $color-gray-800;
  background-color: $colorBlack;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  border-radius: 15px;
  border: 4px solid yellow;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}

.translateSwitcher {
  padding: 0 4.96px 0 10px;
}
